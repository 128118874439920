import { Box, useTheme } from "@mui/material";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import React from "react";
import { mapStyles } from "./mapStyles";
import TogleOperation from "./TogleOperation";
import MapOverlays from "./MapOverlays";
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
function MapOperations() {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <APIProvider
        apiKey={googleMapsApiKey}
        onLoad={() => {
          console.log("Maps API has loaded.");
        }}
      >
        <Map
          defaultZoom={13}
          defaultCenter={{ lat: 13.764937, lng: 100.538296 }}
          gestureHandling={"greedy"}
          styles={
            theme.palette.mode === "dark" ? mapStyles.dark : mapStyles.light
          }
        />
        <TogleOperation />
        <MapOverlays/>
      </APIProvider>
    </Box>
  );
}

export default MapOperations;
