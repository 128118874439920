import React, { createContext, useState } from "react";
import NavBar from "./NavBar";
import { Box, useTheme } from "@mui/material";
import MenuBar from "./MenuBar";
import ConfigurationsAera from "./configurationAeras";
import Dashboards from "./dasboards";
import bg from "./images/bg2.jpg";

export const MainLayOutContect = createContext(null);
function MainLayout() {
  const [togleCollapse, setTogleCollapse] = useState(true);
  const [isMenu, setIsMenu] = useState(1);
  const theme = useTheme();

  return (
    <MainLayOutContect.Provider
      value={{ togleCollapse, setTogleCollapse, isMenu, setIsMenu }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
          backgroundImage: theme.palette.mode === "dark" ? null : `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode:
            theme.palette.mode === "dark" ? "unset" : "overlay",
          backgroundColor: theme.palette.mode === "dark" ? null : "#FFFFFF90",
        }}
      >
        <NavBar />
        <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden" }}>
          <MenuBar />
          {/* {isMenu === 0 && <Dashboards />} */}
          {isMenu === 1 && <ConfigurationsAera />}
        </Box>
      </Box>
    </MainLayOutContect.Provider>
  );
}

export default MainLayout;
