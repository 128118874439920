import React, { useContext, useState } from "react";
import {
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  List,
  ListSubheader,
  Divider,
  Paper,
  useTheme,
} from "@mui/material";
import {
  BusAlert,
  Campaign,
  Dashboard,
  PieChart,
  Settings,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { MainLayOutContect } from "./MainLayout";

function MenuBar() {
  const { togleCollapse, isMenu, setIsMenu } = useContext(MainLayOutContect);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        px: 1,
        borderTop: 0,
        borderLeft: 0,
        borderBottom: 0,
        borderRadius: 0,
        maxWidth: togleCollapse ? 300 : 72,
        minWidth: togleCollapse ? 300 : 72,       
        backgroundColor: theme.palette.mode === "dark" ? null : "#FFFFFF50",        
      }}
    >
      <List
        sx={{
          width: "100%",
          //bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          togleCollapse && (
            <ListSubheader sx={{backgroundColor:'transparent'}} component="div" id="nested-list-subheader">
              Main Menu
            </ListSubheader>
          )
        }
      >
        <ListItemButton
          sx={{ borderRadius: 2 }}
          selected={isMenu === 0}
          onClick={() => setIsMenu(0)}
        >
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          {togleCollapse && <ListItemText primary="Dashboard" />}
        </ListItemButton>
        <ListItemButton
          sx={{ borderRadius: 2 }}
          selected={isMenu === 1}
          onClick={() => setIsMenu(1)}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          {togleCollapse && <ListItemText primary="Configurations" />}
        </ListItemButton>
      </List>
      <Divider />
      <List
        sx={{
          width: "100%",
          //bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader2"
        subheader={
          togleCollapse && (
            <ListSubheader sx={{backgroundColor:'transparent'}} component="div" id="nested-list-subheader2">
              Analytics
            </ListSubheader>
          )
        }
      >
        <ListItemButton sx={{ borderRadius: 2 }} onClick={handleClick}>
          <ListItemIcon>
            <PieChart />
          </ListItemIcon>
          {togleCollapse && <ListItemText primary="Reports" />}
          {togleCollapse && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        {togleCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4, borderRadius: 2 }}
                selected={isMenu === 2}
                onClick={() => setIsMenu(2)}
              >
                <ListItemIcon>
                  <Campaign />
                </ListItemIcon>
                <ListItemText primary="Area notification" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4, borderRadius: 2 }}
                selected={isMenu === 3}
                onClick={() => setIsMenu(3)}
              >
                <ListItemIcon>
                  <BusAlert />
                </ListItemIcon>
                <ListItemText primary="Driving Alert" />
              </ListItemButton>
            </List>
          </Collapse>
        )}
      </List>
    </Paper>
  );
}

export default MenuBar;
