export class ApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(endpoint, authen = null) {
    return this.request(endpoint, "GET", null, authen);
  }

  async post(endpoint, data, authen = null) {
    return this.request(endpoint, "POST", data, authen);
  }

  async put(endpoint, data, authen = null) {
    return this.request(endpoint, "PUT", data, authen);
  }

  async delete(endpoint, data = null, authen = null) {
    return this.request(endpoint, "DELETE", data, authen);
  }

  async request(endpoint, method, data = null, authen = null) {
    const url = `${this.baseUrl}${endpoint}`;
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (authen) {
      options.headers["authorization"] = authen;
    }

    if (data) {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        //throw new Error(`HTTP error! status: ${response.status}`);
        const text = await response.text();
        if (
          ["Failed to authenticate token", "No token provided"].includes(text)
        ) {
          localStorage.removeItem("token");
          window.location.href = "/authen";
        } else {
          throw new Error(text);
        }
      }
      return await response.json();
    } catch (error) {
      console.error("Fetch API error: ", error);
      throw error;
    }
  }

  imageUrl() {
    return this.baseUrl.replace("/api", "");
  }
}
