import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import trophy from "../images/trophy.png";
import {
  AccessibilityNew,
  CrisisAlert,
  DirectionsCarFilled,
  Inventory2Outlined,
  Newspaper,
  PaidOutlined,
  PeopleAltOutlined,
  PieChartOutline,
  ShutterSpeed,
} from "@mui/icons-material";
import {
  blue,
  deepPurple,
  green,
  grey,
  orange,
  red,
} from "@mui/material/colors";
import ApexCharts from "apexcharts";
import zipCar from "../images/zipcar.png";
import bitbank from "../images/bitbank.png";
import aviato from "../images/aviato.png";
import people from "../images/partners.png";
import speedometer from "../images/speed2.png";
import dangerous from "../images/alert.png";
import { ApiService } from "../utils/ApiService";
import { notiError } from "../utils/notiUltils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Empty } from "antd";
dayjs.extend(utc);

const api = new ApiService(
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEBUG
    : process.env.REACT_APP_API_URL_PRODUCT
);

const MyItems = ({ title, text, icon, iconColor }) => {
  return (
    <Stack direction={"row"} spacing={2}>
      <Avatar
        sx={{
          bgcolor: iconColor,
          color: "#FFFFFF",
          alignSelf: "center",
        }}
        variant="rounded"
      >
        {icon}
      </Avatar>
      <Stack>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </Stack>
    </Stack>
  );
};

const WeeklyOverview = () => {
  useEffect(() => {
    const options = {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        height: 206,
      },
      series: [
        {
          name: "sales",
          data: [30, 40, 35, 50, 49, 60, 70],
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 6,
          columnWidth: "32%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
        borderColor: "#90A4AE88",
        strokeDashArray: 6,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          title: {
            //formatter: undefined,
            formatter: function (val) {
              return "<span style='color: #000;'>" + val + "</span>";
            },
          },
          formatter: function (val) {
            return "<span style='color: #000;'>" + val + "k</span>";
          },
        },
        x: {
          formatter: function (val) {
            return "<span style='color: #000;'>" + val + "</span>";
          },
        },
      },

      /*theme: {
        mode: "dark",
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },*/

      xaxis: {
        categories: ["Sun", "Mon", "Tue", "Wed", "The", "Fri", "Sat"],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },

      yaxis: {
        labels: {
          style: {
            colors: ["#90A4AE"],
          },
        },
      },
    };

    const chart = new ApexCharts(document.querySelector("#chart"), options);

    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2.5,
      }}
    >
      <Typography variant="body1">Weekly Overview</Typography>
      <div id="chart"></div>
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <Typography variant="h5">45%</Typography>
        <Typography color="text.secondary" variant="body1">
          Your sales performance is 45% 😎 better compared to last month
        </Typography>
      </Stack>
      <Button
        sx={{ mt: 2, textTransform: "none" }}
        fullWidth
        variant="contained"
      >
        Details
      </Button>
    </Card>
  );
};

const TotalEarningItem = ({ icon, title, text, value, percent = 0 }) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Avatar sx={{ bgcolor: "#0000000F" }} src={icon} variant="rounded" />
        <Stack>
          <Typography variant="body1">{title}</Typography>
          <Typography color="text.secondary" variant="caption">
            {text}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body1">{value}</Typography>
        <Box width={"50px"}>
          <LinearProgress
            color={
              percent >= 0 && percent <= 50
                ? "inherit"
                : percent > 50 && percent <= 80
                ? "secondary"
                : "info"
            }
            sx={{ borderRadius: 2, height: 8 }}
            value={percent}
            variant="determinate"
          />
        </Box>
      </Stack>
    </Stack>
  );
};

const TotalEarning = () => {
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2.5,
      }}
    >
      <Typography variant="body1">
        การแจ้งเตือนทั้งหมด
      </Typography>
      <Stack spacing={0.5}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography variant="h4">24,895</Typography>
          <Typography color={green[500]}>^ 10%</Typography>
        </Stack>
        <Typography color="text.secondary">
          เทียบกับ 84,325 เมื่อปีที่แล้ว
        </Typography>
      </Stack>
      <Stack mt={2} spacing={3.5}>
        <TotalEarningItem
          icon={zipCar}
          title="เขตชุมชน"
          text="ความเร็วไม่เกิน 30 - 60 กม./ชม."
          value="24,895"
          percent={81}
        />
        <TotalEarningItem
          icon={bitbank}
          title="จำกัดความเร็ว"
          text="ความเร็วเกิน 30 - 90 กม./ชม."
          value="8,650"
          percent={51}
        />
        <TotalEarningItem
          icon={aviato}
          title="พื้นที่เสี่ยง"
          text="ไหล่ทาง, ทางร่วม, ทางแยก, ไฟแดง, กิ่งไม้"
          value="1,245"
          percent={20}
        />
      </Stack>
    </Card>
  );
};

const TotalProfit = () => {
  const theme = useTheme();
  useEffect(() => {
    const options = {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        height: 88,
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        width: 3,
      },
      series: [
        {
          name: "sales",
          data: [0, 20, 5, 25, 10, 30],
        },
      ],
      tooltip: {
        enabled: false,
      },
      grid: {
        show: true,
        borderColor: "#90A4AE88",
        strokeDashArray: 6,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },

      xaxis: {
        categories: [1, 2, 3, 4, 5, 6],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        //floating: true,
      },
      yaxis: {
        labels: {
          show: false,
        },
        floating: true,
      },
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: theme.palette.mode === "dark" ? "#2d426a" : "#FFF",
            strokeColor: blue[500],
            strokeWidth: 2,
            size: 6,
            shape: "circle",
          },
        ],
      },
    };

    const chart = new ApexCharts(document.querySelector("#chart2"), options);

    chart.render();

    return () => {
      chart.destroy();
    };
  }, [theme]);
  return (
    <Card sx={{ height: 188 }}>
      <Box p={2.5}>
        <Box>
          <Typography variant="h5">$86.4k</Typography>
          <div id="chart2"></div>
          <Typography mt={-1} variant="body1" align="center">
            Total Profit
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

const WeeklyProfit = ({
  icon,
  iconColor,
  title,
  value,
  percent = 0,
  subtitle,
}) => {
  return (
    <Card sx={{ height: 188 }}>
      <Box p={2.5}>
        <Avatar sx={{ color: "#FFF", bgcolor: iconColor }} variant="circular">
          {icon}
        </Avatar>
        <Stack mt={2} spacing={1}>
          <Typography color="text.secondary" variant="body1">
            {title}
          </Typography>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography variant="h5">{value}</Typography>
            <Typography color={percent > 0 ? green[500] : red[300]}>
              {percent > 0 && "+"}
              {percent}%
            </Typography>
          </Stack>
          <Typography color="text.secondary" variant="body2">
            {subtitle}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
};

const VerticalBar = ({ value = 0, color }) => {
  return (
    <Box
      borderRadius={4}
      height={"48px"}
      width={"7%"}
      bgcolor={grey[500]}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"end"}
    >
      <Box
        borderRadius={4}
        //width={'100%'}
        height={value + "%"}
        bgcolor={color}
      />
    </Box>
  );
};

const Sessions = () => {
  return (
    <Card
      sx={{
        height: 188,
        p: 2.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h5">2,856</Typography>
      <Box flexGrow={1} alignContent={"center"}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <VerticalBar value={40} color={red["A200"]} />
          <VerticalBar value={100} color={deepPurple["A200"]} />
          <VerticalBar value={80} color={red["A200"]} />
          <VerticalBar value={60} color={deepPurple["A200"]} />
          <VerticalBar value={90} color={deepPurple["A200"]} />
        </Stack>
      </Box>
      <Typography variant="body1" align="center">
        Sessions
      </Typography>
    </Card>
  );
};

const Performance = ({ id }) => {
  useEffect(() => {
    const options = {
      chart: {
        type: "radar",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        height: 300,
      },
      series: [
        {
          name: "Income",
          data: [70, 87, 80, 90, 75, 85],
        },
        {
          name: "New Worth",
          data: [100, 70, 85, 80, 90, 70],
        },
      ],
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      fill: {
        opacity: 1,
        colors: [deepPurple["A200"], blue["400"]],
      },
      markers: {
        size: 0,
      },
      legend: {
        show: true,
        labels: {
          colors: "#FFF",
        },
        markers: {
          size: 5,
          offsetX: -5,
        },
        itemMargin: {
          horizontal: 10,
        },
      },
      yaxis: {
        show: false,
      },
    };

    const chart = new ApexCharts(document.querySelector(`#${id}`), options);

    chart.render();

    return () => {
      chart.destroy();
    };
  }, [id]);
  return (
    <Card
      sx={{
        p: 2.5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Typography variant="body1">Performance</Typography>
      <div id={id}></div>
    </Card>
  );
};

const TotalAlarmItem = ({ data }) => {
  return (
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
      <img src={data.image} alt="" width={30} />
      <Stack flexGrow={1}>
        <Typography variant="body1">{data.title}</Typography>
        <Typography color="text.secondary" variant="body2">
          {data.text}
        </Typography>
      </Stack>
      <Typography variant="body2" color="text.secondary">
        {data.dateTime}
      </Typography>
    </Stack>
  );
};
const TotalAlarm = ({ title, data = [] }) => {
  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        subheader={<Typography>{title}</Typography>}
        action={
          <Button
            disabled={data.length === 0}
            color="info"
            sx={{ textTransform: "none" }}
          >
            แสดงทั้งหมด
          </Button>
        }
      />
      {data.length > 0 ? (
        <CardContent sx={{ px: 2.5 }}>
          <Stack spacing={2}>
            {data.map((ele, k) => (
              <TotalAlarmItem key={k} data={ele} />
            ))}
          </Stack>
        </CardContent>
      ) : (
        <Box alignContent={"center"} alignSelf={"center"} sx={{ flexGrow: 1 }}>
          <Empty description="" />
        </Box>
      )}
    </Card>
  );
};

const Dashboard1 = () => {
  const [dataSource, setDataSource] = useState({});
  useEffect(() => {
    api
      .get("/dashboard1", localStorage.getItem("token"))
      .then((data) => {
        setDataSource(data);
      })
      .catch((error) => {
        console.error(error);
        notiError({ message: error, position: "center" });
      });
  }, []);
  return (
    <Paper sx={{ p: 2.5, overflow: "hidden" }}>
      <Stack>
        <Typography variant="subtitle1">
          {dataSource?.locationName}! 🎉
        </Typography>
        <Typography color="text.secondary" variant="caption">
          พื้นที่แจ้งเตือนสูงสุดประจำเดือน
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack alignSelf={"center"}>
            <Typography variant="h6">
              {dataSource?.amount ?? 0} ครั้ง
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {dataSource?.percent ?? 0}% ของการแจ้งเตือน 🚀
            </Typography>
            <Button
              sx={{ mt: 1, textTransform: "none" }}
              size="small"
              variant="contained"
            >
              แสดงการแจ้งเตือน
            </Button>
          </Stack>
          <Box>
            <img src={trophy} width={79} alt="" />
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

const Dashboard2 = () => {
  const [dataSource, setDataSource] = useState({});
  useEffect(() => {
    api
      .get("/dashboard2", localStorage.getItem("token"))
      .then((data) => {
        setDataSource(data);
      })
      .catch((error) => {
        console.error(error);
        notiError({ message: error, position: "center" });
      });
  }, []);

  return (
    <Card
      sx={{
        p: 2.5,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle1">รายการข้อมูลมาตรฐาน</Typography>
        <Typography color="text.secondary" variant="caption">
          ทั้งหมด {dataSource.percent}% การเจริญเติบโต 😎 เดือนนี้
        </Typography>
      </Stack>

      <Box mt={2}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
            <MyItems
              icon={<AccessibilityNew />}
              iconColor={deepPurple["A200"]}
              title={"เขตชุมชน"}
              text={dataSource.community}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
            <MyItems
              icon={<ShutterSpeed />}
              iconColor={green[500]}
              title={"จำกัดความเร็ว"}
              text={dataSource.speedLimit}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
            <MyItems
              icon={<CrisisAlert />}
              iconColor={orange[500]}
              title={"พื้นที่เสี่ยง"}
              text={dataSource.dangerous}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 6, md: 3, lg: 3 }}>
            <MyItems
              icon={<DirectionsCarFilled />}
              iconColor={blue[500]}
              title={"ยานพาหนะ"}
              text={dataSource.vehicle}
            />
          </Grid2>
        </Grid2>
      </Box>
    </Card>
  );
};

function App() {
  const theme = useTheme();
  const [eventData, setEventData] = useState([]);
  const [alarmData, setAlarmData] = useState([]);

  useEffect(() => {
    api
      .get("/top5Event", localStorage.getItem("token"))
      .then((data) => {
        const arr = [];
        data.forEach((element) => {
          const { CategoryID, CategoryName, LocationName, GpsDatetime } =
            element;
          arr.push({
            image:
              CategoryID === 0
                ? people
                : CategoryID === 1
                ? speedometer
                : dangerous,
            title: CategoryName,
            text: LocationName,
            dateTime: dayjs(GpsDatetime).utc().format("YYYY-MM-DD HH:mm:ss"),
          });
        });
        setEventData(arr);
      })
      .catch((error) => {
        console.error(error);
        notiError({ message: error, position: "center" });
      });

    api
      .get("/top5Alarm", localStorage.getItem("token"))
      .then((data) => {
        const arr = [];
        data.forEach((element) => {
          const { CategoryID, CategoryName, AlarmName, GpsDatetime } = element;
          arr.push({
            image:
              CategoryID === 0
                ? people
                : CategoryID === 1
                ? speedometer
                : dangerous,
            title: CategoryName,
            text: AlarmName,
            dateTime: dayjs(GpsDatetime).utc().format("YYYY-MM-DD HH:mm:ss"),
          });
        });
        setAlarmData(arr);
      })
      .catch((error) => {
        console.error(error);
        notiError({ message: error, position: "center" });
      });
  }, []);

  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        flexGrow: 1,
        overflow: "auto",
        border: 0,
        borderRadius: 0,
        backgroundColor: theme.palette.mode === "dark" ? null : "#FFFFFF50",
        p: 2,
      }}
    >
      <Container maxWidth="xl">
        <Grid2 container spacing={3}>
          <Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
            <Dashboard1 />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8 }}>
            <Dashboard2 />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
            <WeeklyOverview />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
            <TotalEarning />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
            <Grid2 container spacing={3}>
              <Grid2 size={6}>
                <TotalProfit />
              </Grid2>
              <Grid2 size={6}>
                <WeeklyProfit
                  icon={<PieChartOutline />}
                  iconColor={grey[500]}
                  title="Total Profit"
                  value="$25.6k"
                  percent={42}
                  subtitle={"Weekly Profit"}
                />
              </Grid2>
              <Grid2 size={6}>
                <WeeklyProfit
                  icon={<Newspaper />}
                  iconColor={deepPurple["A200"]}
                  title="New Project"
                  value="862"
                  percent={-18}
                  subtitle={"Yearly Project"}
                />
              </Grid2>
              <Grid2 size={6}>
                <Sessions />
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
            <Performance id={"performance_1"} />
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
            <TotalAlarm title="เหตุการณ์" data={eventData} />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
            <TotalAlarm title="การแจ้งเตือน" data={alarmData} />
          </Grid2>
        </Grid2>
      </Container>
    </Card>
  );
}

export default App;
