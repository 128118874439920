import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Card, Tooltip } from "@mui/material";
import { ControlPosition, MapControl, useMap } from "@vis.gl/react-google-maps";
import React, { useContext, useEffect } from "react";
import { ConfigOperationsContext } from ".";

function TogleOperation() {
  const map = useMap();
  const { display, setDisplay } = useContext(ConfigOperationsContext);

  useEffect(() => {
    if (!map) {
      return;
    }
  }, [map]);
  return (
    <MapControl position={ControlPosition.LEFT_CENTER}>
      <Tooltip
        onClick={() => setDisplay(display === "block" ? "none" : "block")}
        title={display === "block" ? "ยุบแผงด้านข้าง" : "แสดงแผงด้านข้าง"}
        placement="right"
        arrow
        disableHoverListener
      >
        <Card
          elevation={0}
          variant="outlined"
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            py: 1,
            borderLeft: 0,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            height: "48px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {display === "block" ? (
            <KeyboardDoubleArrowLeft />
          ) : (
            <KeyboardDoubleArrowRight />
          )}
        </Card>
      </Tooltip>
    </MapControl>
  );
}

export default TogleOperation;
