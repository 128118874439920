import { Box } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import ConfigOperations from "./ConfigOperations";
import MapOperations from "./MapOperations";
import { ApiService } from "../utils/ApiService";
import { notiError } from "../utils/notiUltils";
export const ConfigOperationsContext = createContext(null);
const api = new ApiService(
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEBUG
    : process.env.REACT_APP_API_URL_PRODUCT
);
function App() {
  const [display, setDisplay] = useState("block");
  const [operation, setOperation] = useState(1);
  const [loading, setLoading] = useState(true);
  const [datagGoFences, setDataGeoFences] = useState([]);
  const [overlaySelected, setOverlaySelected] = useState(null);
  const [editSelected, setEditSelected] = useState(null);
  const [core, setCore] = useState(null);

  const [drawingMode, setDrawingMode] = useState(null);
  const [drawnOverlay, setDrawnOverlay] = useState(null);

  const getGeoFencesData = () => {
    setLoading(true);
    api
      .get("/geoFences", localStorage.getItem("token"))
      .then((data) => {
        data.forEach((a) => (a.key = a.GeoFenceID));
        setDataGeoFences(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        notiError({ message: error, position: "center" });
        setLoading(false);
      });
  };

  const onRow = (data) => {
    setOverlaySelected(data);
  };

  const onEdit = (data) => {
    setOverlaySelected(null);
    setEditSelected(data);
  };

  useEffect(() => {
    getGeoFencesData();
  }, []);

  return (
    <ConfigOperationsContext.Provider
      value={{
        display,
        setDisplay,
        operation,
        setOperation,
        loading,
        datagGoFences,
        setDataGeoFences,
        onRow,
        onEdit,
        overlaySelected,
        editSelected,
        setEditSelected,
        api,
        core,
        setCore,
        drawingMode,
        setDrawingMode,
        drawnOverlay,
        setDrawnOverlay,
        getGeoFencesData,
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: "hidden", display: "flex" }}>
        <ConfigOperations />
        <MapOperations />
      </Box>
    </ConfigOperationsContext.Provider>
  );
}

export default App;
