export const getApiUrl = () => {
  return process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEBUG
    : process.env.REACT_APP_API_URL_PRODUCT;
};
export const sleep = (ms) => {
  return new Promise((callback) => {
    setTimeout(callback, ms);
  });
};
export const parseGeographyToCoordinates = (wkt) => {
  const coordinates = wkt.match(/(\d+\.\d+ \d+\.\d+)/g).map((coord) => {
    const [lng, lat] = coord.split(" ").map(Number);
    return { lat, lng };
  });
  return coordinates;
};

export const numberOnly = /^[0-9]+$/;
