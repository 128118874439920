import { AddCircle } from "@mui/icons-material";
import {
  Button,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ConfigProvider, Space, Table, theme, Button as AntButton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ConfigOperationsContext } from ".";
import { CarFilled, DeleteFilled, EditFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { notiError, notiSuccess } from "../utils/notiUltils";
import VehicleAuthorization from "./VehicleAuthorization";

function PointPreview() {
  const muiTheme = useTheme();
  const {
    setOperation,
    datagGoFences,
    setDataGeoFences,
    loading,
    onRow,
    onEdit,
    api,
  } = useContext(ConfigOperationsContext);
  //const [tableHeight, setTableHeight] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState("");

  const [open, setOpen] = useState(false);

  const rowClassName = (record) => {
    return record.key === selectedRow
      ? muiTheme.palette.mode === "dark"
        ? "highlighted-row-dark"
        : "highlighted-row"
      : "";
  };

  const onClickAdd = () => {
    setSelectedRow(null);
    onRow(null);
    setOperation(2);
  };

  let editable = false;
  let deleteable = false;
  let authorizationable = false;
  const onRowClick = (record) => {
    if (editable) {
      editable = false;
      return;
    }
    if (deleteable) {
      deleteable = false;
      return;
    }
    if (authorizationable) {
      authorizationable = false;
      return;
    }
    onRow(record);
    setSelectedRow(record.key);
  };
  const onEditClick = (record) => {
    editable = true;
    onEdit(record);
    setSelectedRow(record.key);
    setOperation(3);
  };
  const onDelete = async (record) => {
    deleteable = true;
    try {
      const resultDel = await Swal.fire({
        title: `ลบข้อมูล ${record.LocationName}`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#1976D2",
      });

      if (resultDel.isConfirmed) {
        const result = await api.delete(
          `/geoFences/${record.GeoFenceID}`,
          null,
          localStorage.getItem("token")
        );
        const index = datagGoFences.findIndex(
          (a) => a.GeoFenceID === record.GeoFenceID
        );
        datagGoFences[index]?.overlay?.setMap(null);
        onRow(null);
        datagGoFences.splice(index, 1);
        setDataGeoFences([...datagGoFences]);
        notiSuccess({ message: result.message, position: "center" });
      }
    } catch (error) {
      console.log(error);
      notiError({ message: error, position: "center" });
    }
  };
  const onAuthorization = (record) => {
    authorizationable = true;
    setSelectedPoint(record);
    setOpen(true);
  };

  /*useEffect(() => {
    const handleOnWindowResize = () => {
      const screenHeight = window.innerHeight;
      setTableHeight(screenHeight - 295);
    };
    window.addEventListener("resize", handleOnWindowResize);
    handleOnWindowResize();
    return () => {
      window.removeEventListener("resize", handleOnWindowResize);
    };
  }, []);*/

  useEffect(() => {
    const elements = document.getElementsByClassName("ant-table-body");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.overflow = "auto";
    }
  }, [datagGoFences]);

  return (
    <>
      <CardContent>
        <Stack mt={2} spacing={3}>
          {/* <Typography variant="h6">HAZARD POINT</Typography> */}
          <Typography variant="h6">พื้นที่จุดเสี่ยงจุดอันตราย</Typography>
          <Button
            onClick={onClickAdd}
            startIcon={<AddCircle />}
            variant="contained"
            size="large"
          >
            สร้างพื้นที่จุดเสี่ยงจุดอันตราย
          </Button>
          <ConfigProvider
            theme={{
              token:
                muiTheme.palette.mode === "dark"
                  ? {
                      fontFamily: "kanit",
                      colorBgContainer: "#223862",
                      colorBgBase: "#0e1626",
                    }
                  : {
                      fontFamily: "kanit",
                      //colorBgContainer: "#f6f5f5",
                      colorBgContainer: "transparent",
                      colorBorder:'#555555',
                      colorBorderSecondary:"#BBBBBB"
                    },
              algorithm:
                muiTheme.palette.mode === "dark"
                  ? theme.darkAlgorithm
                  : theme.defaultAlgorithm,
            }}
          >
            <Table
              columns={[
                {
                  title: "Operate",
                  align: "center",
                  width: 120,
                  render: (record) => (
                    <Space>
                      <AntButton
                        size="small"
                        onClick={() => onEditClick(record)}
                        shape="circle"
                        icon={<EditFilled />}
                      />
                      <AntButton
                        size="small"
                        onClick={() => onDelete(record)}
                        shape="circle"
                        icon={<DeleteFilled />}
                      />
                      <AntButton
                        onClick={() => onAuthorization(record)}
                        size="small"
                        shape="circle"
                        icon={<CarFilled />}
                      />
                    </Space>
                  ),
                },
                { title: "Location", dataIndex: "LocationName" },
                { title: "Category", dataIndex: "CategoryName" },
              ]}
              //pagination={false}
              loading={loading}
              dataSource={datagGoFences}
              scroll={{ y: "calc(100vh - 350px)" }}
              onRow={(record) => ({
                onClick: () => {
                  onRowClick(record);
                },
              })}
              rowClassName={rowClassName}
              bordered
              pagination={{ pageSize: 20 }}
            />
          </ConfigProvider>
        </Stack>
      </CardContent>
      {open && (
        <VehicleAuthorization
          open={open}
          setOpen={setOpen}
          selectedPoint={selectedPoint}
        />
      )}
    </>
  );
}

export default PointPreview;
