import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ConfigOperationsContext } from ".";
import Swal from "sweetalert2";
import { notiError, notiSuccess } from "../utils/notiUltils";
import Dropdown from "../components/Dropdown";
import { alarmData, categoryData, getCategoryName } from "./initialData";
import {
  Cancel,
  CloudUpload,
  Delete,
  GraphicEq,
  HideImage,
  Save,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Image, Spin } from "antd";
import { numberOnly } from "../utils/apiUtils";
import { LoadingButton } from "@mui/lab";

function PointEdit() {
  const { setOperation, setEditSelected, editSelected, api, core } = useContext(
    ConfigOperationsContext
  );
  const [categorySelected, setCategorySelected] = useState(0);
  const [alarmSelected, setAlarmSelected] = useState("");
  const [alarmDataSource, setAlarmDataSource] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [speedLimit, setSpeedLimit] = useState("");
  const [audioSrc, setAudioSrc] = useState(null);
  const [percent, setPercent] = useState(0);
  const [showPercent, setShowPercent] = useState(false);
  const [radius, setRadius] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [uploading, setUploading] = useState(false);

  const [locationNameError, setLocationNameError] = useState("");
  const [speedLimitError, setSpeedLimitError] = useState("");
  const [radiusError, setRadiusError] = useState("");

  const [loading, setLoading] = useState(false);

  const audioRef = useRef(null);
  const refFile = useRef(null);
  const audioPlay = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    const sound = alarmData.find((a) => a.value === alarmSelected);
    setAudioSrc(sound.soundPath);
    setPercent(0);
    audioRef.current.load();
    setShowPercent(true);
  };

  const handlePlaySound = () => {
    audioRef.current.play();
  };

  const timeupdate = () => {
    if (audioRef.current.currentTime && audioRef.current.duration) {
      setPercent(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      );
    }
  };

  const handleEndedSound = () => {
    setShowPercent(false);
  };

  const onCancleClick = () => {
    setOperation(1);
    setEditSelected(null);
  };

  const onSave = () => {
    if (!locationName) {
      setLocationNameError("Enter location name.");
      return;
    }
    if (categorySelected === 1) {
      if (!speedLimit) {
        setSpeedLimitError("Enter speed limit.");
        return;
      }
      if (!numberOnly.test(speedLimit)) {
        setSpeedLimitError("Enter numbers only.");
        return;
      }
    }
    if (editSelected.GeotypeID === 3) {
      if (!radius) {
        setRadiusError("Enter radius.");
        return;
      }
      if (!numberOnly.test(radius)) {
        setRadiusError("Enter numbers only.");
        return;
      }
    }

    Swal.fire({
      title: "ยืนยันบันทึกการแก้ไข?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#1976D2",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const { overlay, overlayEdit, GeoFenceID, GeotypeID } = editSelected;
        const data = {
          CategoryID: categorySelected,
          AlarmID: alarmSelected,
          GeotypeID: editSelected.GeotypeID,
          LocationName: locationName,
          SpeedLimit: categorySelected === 1 ? speedLimit : "",
          ImagePath: imagePath,
          Radius: radius,
        };

        const onError = (error) => {
          console.log(error);
          notiError({ message: error, position: "center" });
          setLoading(false);
        };
        const onSuccess = (message) => {          
          editSelected.CategoryID = categorySelected;
          editSelected.CategoryName = getCategoryName(categorySelected)
          editSelected.AlarmID = alarmSelected;
          editSelected.LocationName = locationName;
          editSelected.SpeedLimit = data.SpeedLimit ? data.SpeedLimit : null;
          editSelected.Radius = data.Radius ? data.Radius : null;
          editSelected.ImagePath = data.ImagePath ? data.ImagePath : null;
         
          

          setEditSelected(null);
          setOperation(1);
          notiSuccess({ message, position: "center" });
          setLoading(false);
        };

        switch (GeotypeID) {
          case 1:
            data.Latitude = overlayEdit.getCenter().lat();
            data.Longitude = overlayEdit.getCenter().lng();
            data.Radius = overlayEdit.getRadius();
            api
              .put(
                `/geoFences/${GeoFenceID}`,
                data,
                localStorage.getItem("token")
              )
              .then((result) => {
                overlay?.setCenter(overlayEdit?.getCenter());
                overlay?.setRadius(overlayEdit?.getRadius());
                onSuccess(result.message);
              })
              .catch(onError);
            break;
          case 2:
          case 4:
            const paths = overlayEdit
              ?.getPaths()
              .getArray()
              .map((path) =>
                path
                  .getArray()
                  .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }))
              );
            const bounds = new core.LatLngBounds();
            const pathII = overlayEdit?.getPath();
            const coordinates = [];
            for (let i = 0; i < pathII.getLength(); i++) {
              const coordinate = pathII.getAt(i);
              bounds.extend(coordinate);
              coordinates.push({
                lat: coordinate.lat(),
                lng: coordinate.lng(),
              });
            }
            if (
              coordinates[0].lat !== coordinates[coordinates.length - 1].lat ||
              coordinates[0].lng !== coordinates[coordinates.length - 1].lng
            ) {
              coordinates[coordinates.length - 1].lat = coordinates[0].lat;
              coordinates[coordinates.length - 1].lng = coordinates[0].lng;
            }
            const center = bounds.getCenter();
            data.Latitude = center.lat();
            data.Longitude = center.lng();
            data.Coordinates = coordinates;
            api
              .put(
                `/geoFences/${GeoFenceID}`,
                data,
                localStorage.getItem("token")
              )
              .then((result) => {
                overlay?.setPaths(paths);
                onSuccess(result.message);
              })
              .catch(onError);
            break;
          case 3:
            const path = overlayEdit
              .getPath()
              .getArray()
              .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
            const index = Math.floor(path.length / 2);
            const centerII = path[index];
            data.Latitude = centerII.lat;
            data.Longitude = centerII.lng;
            data.Coordinates = path;
            api
              .put(
                `/geoFences/${GeoFenceID}`,
                data,
                localStorage.getItem("token")
              )
              .then((result) => {
                overlay?.setPath(path);
                onSuccess(result.message);
              })
              .catch(onError);
            break;
          default:
            break;
        }
      }
    });
  };

  const handleFileChange = (el) => {
    const selectedFile = el.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      fetch(`${api.baseUrl}/upload`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.error) {
            notiError({ message: result.error });
          } else {
            setImagePath(result.fileUrl);
          }
          setUploading(false);
        })
        .catch((e) => {
          console.log(e);
          notiError({ message: "อัพโหลดรูปภาพไม่สำเร็จ" });
          setUploading(false);
          refFile.current.value = "";
        });
    }
  };

  const handleClearImage = () => {
    setImagePath("");
    refFile.current.value = "";
  };

  useEffect(() => {
    setCategorySelected(editSelected.CategoryID ?? 0);
    setAlarmSelected(editSelected.AlarmID ?? 0);
    setLocationName(editSelected.LocationName ?? "");
    setSpeedLimit(editSelected.SpeedLimit ?? "");
    setRadius(editSelected.Radius ?? "");
    setImagePath(editSelected.ImagePath ?? "");
  }, [editSelected]);

  useEffect(() => {
    const defaultAlarm = alarmData.filter((a) =>
      a.category.includes(categorySelected)
    );
    if (defaultAlarm.find((a) => a.value === editSelected?.AlarmID)) {
      setAlarmSelected(editSelected?.AlarmID);
    } else {
      setAlarmSelected(defaultAlarm[0]?.value);
    }
    setAlarmDataSource(defaultAlarm);
  }, [categorySelected, editSelected]);

  return (
    <>
      <CardContent>
        <Stack mt={2} spacing={3}>
          {/* <Typography variant="h6">EDIT HAZARD POINT</Typography> */}
          <Typography variant="h6">แก้ไขพื้นที่จุดเสี่ยงจุดอันตราย</Typography>
          <Stack spacing={2}>
            <Dropdown
              variant="outlined"
              label="Category"
              options={categoryData}
              value={categorySelected}
              onChange={(value) => {
                setCategorySelected(value);
              }}
            />
            <Dropdown
              variant="outlined"
              label="Alarm"
              options={alarmDataSource}
              value={alarmSelected}
              onChange={(value) => {
                setAlarmSelected(value);
              }}
            />
            <Stack alignItems={"center"} direction={"row"} spacing={2}>
              <Stack direction={"row"} spacing={1}>
                <IconButton onClick={audioPlay}>
                  <GraphicEq />
                </IconButton>
                <Typography noWrap component={"div"} alignSelf={"center"}>
                  เล่นเสียง
                </Typography>
              </Stack>
              {showPercent && (
                <Box flexGrow={1} pt={0.4}>
                  <LinearProgress
                    variant="determinate"
                    value={percent}
                    color="inherit"
                  />
                </Box>
              )}

              <audio
                ref={audioRef}
                onLoadedData={handlePlaySound}
                onTimeUpdate={timeupdate}
                onEnded={handleEndedSound}
              >
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </Stack>

            <TextField
              onChange={(el) => {
                locationNameError && setLocationNameError("");
                setLocationName(el.target.value);
              }}
              value={locationName}
              label="Location Name"
              autoComplete="off"
              error={locationNameError ? true : false}
              helperText={locationNameError}
              slotProps={{ inputLabel: { shrink: true } }}
            />
            <TextField
              slotProps={{ inputLabel: { shrink: true } }}
              label="Speed Limit"
              value={speedLimit}
              autoComplete="off"
              disabled={categorySelected !== 1}
              error={speedLimitError ? true : false}
              helperText={speedLimitError}
              onChange={(el) => {
                speedLimitError && setSpeedLimitError("");
                setSpeedLimit(el.target.value);
              }}
            />
            <TextField
              label="Radius"
              slotProps={{ inputLabel: { shrink: true } }}
              value={radius}
              autoComplete="off"
              disabled={editSelected.GeotypeID === 3 ? false : true}
              error={radiusError ? true : false}
              helperText={radiusError}
              onChange={(el) => {
                radiusError && setRadiusError("");
                setRadius(el.target.value);
              }}
            />

            <Box>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <input
                  ref={refFile}
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Button
                  onClick={() => refFile?.current.click()}
                  color="info"
                  startIcon={<CloudUpload />}
                >
                  อัพโหลดรูปภาพ
                </Button>
                <Button
                  onClick={handleClearImage}
                  color="info"
                  startIcon={<Delete />}
                >
                  ลบรูปภาพ
                </Button>
              </Stack>
              <Card
                elevation={0}
                sx={{
                  height: 240,
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="outlined"
              >
                {!imagePath && !uploading && (
                  <CardContent>
                    <Stack alignItems={"center"} spacing={1}>
                      <HideImage sx={{ fontSize: "64px", color: grey[400] }} />
                      <Typography color={grey[400]} variant="caption">
                        ไม่มีรูปภาพ
                      </Typography>
                    </Stack>
                  </CardContent>
                )}
                {imagePath && !uploading && (
                  <Image src={`${api.imageUrl()}${imagePath}`} />
                )}
                {uploading && (
                  <CardContent>
                    <Spin />
                  </CardContent>
                )}
              </Card>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions sx={{ ml: 1 }}>
        <Button
          startIcon={<Cancel />}
          onClick={onCancleClick}
          variant="outlined"
          color="info"
        >
          ยกเลิก
        </Button>
        <LoadingButton
          startIcon={<Save />}
          onClick={onSave}
          variant="contained"
          loading={loading}
        >
          บันทึก
        </LoadingButton>
      </CardActions>
    </>
  );
}

export default PointEdit;
