import "@fontsource/kanit/100.css";
import "@fontsource/kanit/200.css";
import "@fontsource/kanit/300.css";
import "@fontsource/kanit/400.css";
import "@fontsource/kanit/500.css";
import "@fontsource/kanit/600.css";
import "@fontsource/kanit/700.css";
import "@fontsource/kanit/800.css";
import "@fontsource/kanit/900.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { CssBaseline } from "@mui/material";
import MainLayout from "./MainLayout";
import { createContext, useState } from "react";
import LoginSystem from "./loginSystem";
import "./Maps.css";
/*const darkTheme = createTheme({
  palette: {
    mode: 'dark', // โหมดมืด
    primary: {
      main: '#90caf9', // สี primary สำหรับปุ่มหรือองค์ประกอบหลัก
    },
    secondary: {
      main: '#f48fb1', // สีรองสำหรับปุ่มเสริม
    },
    background: {
      default: '#121212', // พื้นหลังของหน้าเว็บ
      paper: '#1e1e1e', // พื้นหลังขององค์ประกอบ เช่น Card, AppBar
    },
    text: {
      primary: '#ffffff', // สีตัวอักษรหลัก
      secondary: '#b0bec5', // สีตัวอักษรรอง
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1e1e', // AppBar สีพื้นหลัง
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // ไม่แปลงข้อความเป็นตัวพิมพ์ใหญ่
          borderRadius: '8px', // ขอบโค้งมน
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1e1e', // พื้นหลังของ Paper เช่น Card
        },
      },
    },
  },
});*/
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
    /*secondary: {
      main: "#8C57FF",
    },*/
    background: { paper: "#223862" },
  },
  typography: {
    fontFamily: "kanit",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: "#283f6c",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#283f6c",
        },
      },
    },
  },
  /*components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b", // ปรับแต่งสี Scrollbar
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            backgroundColor: "#2b2b2b",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "#6b6b6b",
            minHeight: "24px",
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },*/
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: { paper: "#f6f5f5" },
  },
  typography: {
    fontFamily: "kanit",
  },
});
export const AppContext = createContext();
function App() {
  const [theme, setTheme] = useState(lightTheme);
  const handleMode = () => {
    setTheme(theme.palette.mode === "dark" ? lightTheme : darkTheme);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AppContext.Provider value={{ handleMode }}>
                <MainLayout />
              </AppContext.Provider>
            }
          />
          <Route path="/authen" element={<LoginSystem />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
