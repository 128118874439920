import {
  ControlPosition,
  InfoWindow,
  MapControl,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { ConfigOperationsContext } from ".";
import { parseGeographyToCoordinates } from "../utils/apiUtils";
import { Card, Stack, TextField, Typography } from "@mui/material";
import { Image } from "antd";
import { notiError } from "../utils/notiUltils";

function MapOverlays() {
  const map = useMap();
  const maps = useMapsLibrary("maps");
  const core = useMapsLibrary("core");
  const drawing = useMapsLibrary("drawing");
  const {
    datagGoFences,
    overlaySelected,
    api,
    editSelected,
    setCore,
    drawingMode,
    operation,
    setDrawnOverlay,
  } = useContext(ConfigOperationsContext);

  const [positionOfInfoWindow, setPositionOfInfoWindow] = useState(null);
  const [position, setPosition] = useState("");
  const [markerInfo, setMarkerInfo] = useState(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const latLngRegex =
        /^\s*[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)\s*$/;
      if (latLngRegex.test(position)) {
        const arrPosition = position.split(",");
        setMarkerInfo(
          new maps.InfoWindow({
            position: {
              lat: parseFloat(arrPosition[0]),
              lng: parseFloat(arrPosition[1]),
            },
            headerContent: "Current Point",
            content: arrPosition.join(","),
          })
        );
        setPosition("");
      } else {
        notiError({ message: "พิกัดไม่ถูกต้อง", position: "bottom-right" });
      }
    }
  };

  useEffect(() => {
    if (!markerInfo || !map) {
      return;
    }
    markerInfo.setMap(map);
    map.setCenter({
      lat: markerInfo.position.lat(),
      lng: markerInfo.position.lng(),
    });

    return () => {
      markerInfo.setMap(null);
    };
  }, [markerInfo, map]);

  useEffect(() => {
    setMarkerInfo(null);
  }, [operation]);

  useEffect(() => {
    if (!map || !maps || !datagGoFences) {
      return;
    }
    datagGoFences.forEach((geoFence) => {
      const { Radius, GeotypeID, WKT, Latitude, Longitude } = geoFence;
      switch (GeotypeID) {
        case 1:
          const circle = new maps.Circle({
            center: { lat: Latitude, lng: Longitude },
            radius: Radius,
            fillColor: "#1976D2",
            strokeColor: "#1976D2",
            strokeWeight: 1,
          });
          circle.setMap(map);
          geoFence.overlay = circle;
          break;
        case 2:
        case 4:
          const paths = parseGeographyToCoordinates(WKT);
          const polygon = new maps.Polygon({
            paths,
            radius: Radius,
            fillColor: "#1976D2",
            strokeColor: "#1976D2",
            strokeWeight: 1,
          });
          polygon.setMap(map);
          geoFence.overlay = polygon;
          break;
        case 3:
          const flightPlanCoordinates = parseGeographyToCoordinates(WKT);
          const flightPath = new maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: "#1976D2",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          flightPath.setMap(map);
          geoFence.overlay = flightPath;
          break;
        default:
          break;
      }
    });
    return () => {
      datagGoFences.forEach((geoFence) => {
        const { overlay } = geoFence;
        overlay?.setMap(null);
      });
    };
  }, [map, maps, datagGoFences]);

  useEffect(() => {
    if (!overlaySelected || !maps || !core || !map) {
      return;
    }
    const { overlay } = overlaySelected;

    (overlay instanceof maps.Polygon ||
      overlaySelected instanceof maps.Rectangle ||
      overlay instanceof maps.Circle) &&
      overlay?.setOptions({ fillColor: "green" });

    overlay instanceof maps.Polyline &&
      overlay?.setOptions({ strokeColor: "green" });

    if (
      overlay instanceof maps.Polygon ||
      overlay instanceof maps.Rectangle ||
      overlay instanceof maps.Polyline
    ) {
      const bounds = new core.LatLngBounds();
      const path = overlay?.getPath();
      for (let i = 0; i < path.getLength(); i++) {
        bounds.extend(path.getAt(i));
      }
      if (overlay instanceof maps.Polyline) {
        const index = Math.floor(path.getLength() / 2);
        const center = path.getAt(index);
        setPositionOfInfoWindow(center);
      } else {
        const center = bounds.getCenter();
        setPositionOfInfoWindow(center);
      }
      map.fitBounds(bounds);
    } else if (overlay instanceof maps.Circle) {
      const bounds = overlay?.getBounds();
      const center = bounds.getCenter();
      setPositionOfInfoWindow(center);
      map.fitBounds(bounds);
    }

    return () => {
      (overlay instanceof maps.Polygon ||
        overlay instanceof maps.Rectangle ||
        overlay instanceof maps.Circle) &&
        overlay?.setOptions({ fillColor: "#1976D2" });

      overlay instanceof maps.Polyline &&
        overlay?.setOptions({ strokeColor: "#1976D2" });

      setPositionOfInfoWindow(null);
    };
  }, [overlaySelected, maps, core, map]);

  useEffect(() => {
    if (!editSelected || !map || !maps || !core) {
      return;
    }
    const { overlay } = editSelected;
    overlay?.setMap(null);
    if (overlay instanceof maps.Circle) {
      const circleOptions = {
        strokeColor: overlay.get("strokeColor"),
        strokeOpacity: overlay.get("strokeOpacity"),
        strokeWeight: overlay.get("strokeWeight"),
        fillColor: "#FF0000",
        fillOpacity: overlay.get("fillOpacity"),
        center: overlay.getCenter(),
        radius: overlay.getRadius(),
        map: map,
        editable: true,
        draggable: true,
      };
      editSelected.overlayEdit = new maps.Circle(circleOptions);
      map.fitBounds(overlay?.getBounds());
    }

    if (overlay instanceof maps.Polygon || overlay instanceof maps.Rectangle) {
      const paths = overlay
        .getPaths()
        .getArray()
        .map((path) =>
          path
            .getArray()
            .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }))
        );
      const circleOptions = {
        paths: paths,
        strokeColor: overlay.get("strokeColor"),
        strokeOpacity: overlay.get("strokeOpacity"),
        strokeWeight: overlay.get("strokeWeight"),
        fillColor: "#FF0000",
        fillOpacity: overlay.get("fillOpacity"),
        map: map,
        editable: true,
        draggable: true,
      };
      editSelected.overlayEdit = new maps.Polygon(circleOptions);
      const bounds = new core.LatLngBounds();
      const path = overlay?.getPath();
      for (let i = 0; i < path.getLength(); i++) {
        bounds.extend(path.getAt(i));
      }
      map.fitBounds(bounds);
    }

    if (overlay instanceof maps.Polyline) {
      const path = overlay
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
      const polylineOptions = {
        path: path,
        geodesic: overlay.get("geodesic"),
        strokeColor: "#FF0000",
        strokeOpacity: overlay.get("strokeOpacity"),
        strokeWeight: overlay.get("strokeWeight"),
        map: map,
        editable: true,
        draggable: true,
      };
      editSelected.overlayEdit = new maps.Polyline(polylineOptions);
      const bounds = new core.LatLngBounds();
      const paths = overlay?.getPath();
      for (let i = 0; i < paths.getLength(); i++) {
        bounds.extend(paths.getAt(i));
      }
      map.fitBounds(bounds);
    }

    return () => {
      overlay?.setMap(map);
      editSelected?.overlayEdit?.setMap(null);
      map.setZoom(13);
    };
  }, [editSelected, maps, core, map]);

  useEffect(() => {
    if (!core || !setCore) {
      return;
    }
    setCore(core);
  }, [core, setCore]);

  useEffect(() => {
    if (!map || !drawing) {
      return;
    }
    const newDrawingManager = new drawing.DrawingManager({
      map,
      drawingMode: null,
      drawingControl: false,
      drawingControlOptions: {
        position: ControlPosition.TOP_CENTER,
        drawingModes: [
          drawing.OverlayType.CIRCLE,
          drawing.OverlayType.POLYGON,
          drawing.OverlayType.POLYLINE,
          drawing.OverlayType.RECTANGLE,
        ],
      },
      circleOptions: {
        editable: true,
        clickable: true,
        fillColor: "#FF0000",
        strokeColor: "#FF0000",
        strokeWeight: 1,
      },
      polygonOptions: {
        editable: true,
        draggable: true,
        fillColor: "#FF0000",
        strokeColor: "#FF0000",
      },
      rectangleOptions: {
        editable: true,
        draggable: true,
        fillColor: "#FF0000",
        strokeColor: "#FF0000",
        strokeWeight: 1,
      },
      polylineOptions: {
        editable: true,
        draggable: true,
        fillColor: "#FF0000",
        strokeColor: "#FF0000",
      },
    });

    newDrawingManager.setDrawingMode(
      drawingMode === 1
        ? drawing.OverlayType.CIRCLE
        : drawingMode === 2
        ? drawing.OverlayType.POLYGON
        : drawingMode === 3
        ? drawing.OverlayType.POLYLINE
        : drawingMode === 4
        ? drawing.OverlayType.RECTANGLE
        : null
    );

    let overlay;
    setDrawnOverlay(null);
    const overlaycomplete = (event) => {
      overlay = event.overlay;
      setDrawnOverlay(overlay);
      newDrawingManager.setDrawingMode(null);
    };
    newDrawingManager.addListener("overlaycomplete", overlaycomplete);

    return () => {
      newDrawingManager.setMap(null);
      overlay?.setMap(null);
    };
  }, [drawingMode, drawing, map, setDrawnOverlay]);

  return (
    <>
      <InfoWindow
        position={positionOfInfoWindow}
        shouldFocus={false}
        minWidth={300}
        headerContent={
          <Typography variant="h6" color="#000">
            {overlaySelected?.CategoryName}
          </Typography>
        }
      >
        <Stack mb={1}>
          <Typography variant="caption">
            ชื่อสถานที่ : {overlaySelected?.LocationName}
          </Typography>
          <Typography variant="caption">
            จำกัดความเร็ว : {overlaySelected?.SpeedLimit ?? "-"} กม./ชม.
          </Typography>
          <Typography variant="caption">
            รัศมี : {overlaySelected?.Radius ?? "-"} เมตร
          </Typography>
        </Stack>
        {overlaySelected?.ImagePath && (
          <Card
            sx={{
              height: 180,
              width: 320,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Image
              src={`${api.imageUrl()}${overlaySelected?.ImagePath}`}
              alt=""
            />
          </Card>
        )}
        {!overlaySelected?.ImagePath && (
          <Image
            width={320}
            height={180}
            src={`/images/unnamed.png`}
            alt=""
            preview={false}
          />
        )}
      </InfoWindow>
      {operation === 2 && (
        <MapControl position={ControlPosition.TOP_LEFT}>
          <TextField
            size="small"
            slotProps={{
              input: {
                sx: {
                  bgcolor: "#FFFFFF",
                  color: "#333333",
                  borderRadius: "4px",
                },
              },
            }}
            sx={{ mt: 1.2 }}
            variant="outlined"
            placeholder="Enter Place"
            onKeyDown={handleKeyDown}
            onChange={(el) => setPosition(el.target.value)}
            autoComplete="off"
            value={position}
          />
        </MapControl>
      )}
    </>
  );
}

export default MapOverlays;
