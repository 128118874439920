import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ConfigOperationsContext } from ".";
import Swal from "sweetalert2";
import { notiError, notiSuccess } from "../utils/notiUltils";
import Dropdown from "../components/Dropdown";
import { alarmData, categoryData } from "./initialData";
import {
  BackHandTwoTone,
  Cancel,
  CircleTwoTone,
  CloudUpload,
  Delete,
  GraphicEq,
  HideImage,
  LabelImportantTwoTone,
  RectangleTwoTone,
  Save,
  Timeline,
} from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Image, Spin } from "antd";
import { numberOnly } from "../utils/apiUtils";
import { LoadingButton } from "@mui/lab";

function PointAddNew() {
  const {
    setOperation,
    api,
    core,
    drawingMode,
    setDrawingMode,
    drawnOverlay,
    getGeoFencesData,
  } = useContext(ConfigOperationsContext);
  const [categorySelected, setCategorySelected] = useState(0);
  const [alarmSelected, setAlarmSelected] = useState("");
  const [alarmDataSource, setAlarmDataSource] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [speedLimit, setSpeedLimit] = useState("");
  const [audioSrc, setAudioSrc] = useState(null);
  const [percent, setPercent] = useState(0);
  const [showPercent, setShowPercent] = useState(false);
  const [radius, setRadius] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [uploading, setUploading] = useState(false);

  const [locationNameError, setLocationNameError] = useState("");
  const [speedLimitError, setSpeedLimitError] = useState("");
  const [radiusError, setRadiusError] = useState("");

  const [loading, setLoading] = useState(false);

  const audioRef = useRef(null);
  const refFile = useRef(null);
  const audioPlay = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    const sound = alarmData.find((a) => a.value === alarmSelected);
    setAudioSrc(sound.soundPath);
    setPercent(0);
    audioRef.current.load();
    setShowPercent(true);
  };

  const handlePlaySound = () => {
    audioRef.current.play();
  };

  const timeupdate = () => {
    if (audioRef.current.currentTime && audioRef.current.duration) {
      setPercent(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      );
    }
  };

  const handleEndedSound = () => {
    setShowPercent(false);
  };

  const onCancleClick = () => {
    setOperation(1);
    setDrawingMode(null);
  };

  const onSave = async () => {
    if (!locationName) {
      setLocationNameError("Enter location name.");
      return;
    }
    if (categorySelected === 1) {
      if (!speedLimit) {
        setSpeedLimitError("Enter speed limit.");
        return;
      }
      if (!numberOnly.test(speedLimit)) {
        setSpeedLimitError("Enter numbers only.");
        return;
      }
    }

    if (!drawnOverlay) {
      notiError({
        message: "ยังไม่มีการวาดพื้นที่โปรดวาดพื้นที่ก่อนทำการบันทึก",
        position: "center",
      });
      return;
    }

    if (drawingMode === 3) {
      if (!radius) {
        setRadiusError("Enter radius.");
        return;
      }
      if (!numberOnly.test(radius)) {
        setRadiusError("Enter numbers only.");
        return;
      }
    }

    try {
      const confirmResult = await Swal.fire({
        title: "ยืนยันบันทึกข้อมูล?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        confirmButtonColor: "#1976D2",
      });
      if (confirmResult.isConfirmed) {
        setLoading(true);
        const data = {
          CategoryID: categorySelected,
          AlarmID: alarmSelected,
          GeotypeID: drawingMode,
          LocationName: locationName,
          SpeedLimit: categorySelected === 1 ? speedLimit : "",
          ImagePath: imagePath,
          Radius: radius,
        };

        const onSuccess = (message) => {
          setDrawingMode(null);
          setLocationName("");
          setRadius("");
          setSpeedLimit("");
          getGeoFencesData();
          setImagePath("");
          refFile.current.value = "";
          notiSuccess({ message, position: "center" });
          setLoading(false);
        };

        if (drawingMode === 1) {
          data.Latitude = drawnOverlay.getCenter().lat();
          data.Longitude = drawnOverlay.getCenter().lng();
          data.Radius = drawnOverlay.getRadius();
          const result = await api.post(
            `/geoFences`,
            data,
            localStorage.getItem("token")
          );
          onSuccess(result.message);
        }

        if (drawingMode === 2) {
          const bounds = new core.LatLngBounds();
          const path = drawnOverlay?.getPath();
          const coordinates = [];
          for (let i = 0; i < path.getLength(); i++) {
            const coordinate = path.getAt(i);
            bounds.extend(coordinate);
            coordinates.push({
              lat: coordinate.lat(),
              lng: coordinate.lng(),
            });
          }
          if (
            coordinates[0].lat !== coordinates[coordinates.length - 1].lat ||
            coordinates[0].lng !== coordinates[coordinates.length - 1].lng
          ) {
            coordinates.push({
              lat: coordinates[0].lat,
              lng: coordinates[0].lng,
            });
          }
          const center = bounds.getCenter();
          data.Latitude = center.lat();
          data.Longitude = center.lng();
          data.Coordinates = coordinates;
          const result = await api.post(
            `/geoFences`,
            data,
            localStorage.getItem("token")
          );
          onSuccess(result.message);
        }

        if (drawingMode === 3) {
          const path = drawnOverlay?.getPath();
          const coordinates = [];
          for (let i = 0; i < path.getLength(); i++) {
            const coord = path.getAt(i);
            coordinates.push({ lat: coord.lat(), lng: coord.lng() });
          }
          const index = Math.floor(coordinates.length / 2);
          const center = coordinates[index];
          data.Latitude = center.lat;
          data.Longitude = center.lng;
          data.Coordinates = coordinates;
          const result = await api.post(
            `/geoFences`,
            data,
            localStorage.getItem("token")
          );
          onSuccess(result.message);
        }

        if (drawingMode === 4) {
          const bounds = drawnOverlay.getBounds();
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();
          const rectangleCoordinates = [
            { lat: sw.lat(), lng: sw.lng() },
            { lat: sw.lat(), lng: ne.lng() },
            { lat: ne.lat(), lng: ne.lng() },
            { lat: ne.lat(), lng: sw.lng() },
            { lat: sw.lat(), lng: sw.lng() },
          ];
          const center = bounds.getCenter();
          data.Latitude = center.lat();
          data.Longitude = center.lng();
          data.Coordinates = rectangleCoordinates;
          const result = await api.post(
            `/geoFences`,
            data,
            localStorage.getItem("token")
          );
          onSuccess(result.message);
        }

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      notiError({ message: error, position: "center" });
      setLoading(false);
    }
  };

  const handleFileChange = (el) => {
    const selectedFile = el.target.files[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      fetch(`${api.baseUrl}/upload`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.error) {
            notiError({ message: result.error });
          } else {
            setImagePath(result.fileUrl);
          }
          setUploading(false);
        })
        .catch((e) => {
          console.log(e);
          notiError({ message: "อัพโหลดรูปภาพไม่สำเร็จ" });
          setUploading(false);
          refFile.current.value = "";
        });
    }
  };

  const handleClearImage = () => {
    setImagePath("");
    refFile.current.value = "";
  };

  useEffect(() => {
    const defaultAlarm = alarmData.filter((a) =>
      a.category.includes(categorySelected)
    );
    setAlarmDataSource(defaultAlarm);
    setAlarmSelected(defaultAlarm[0]?.value ?? "");
  }, [categorySelected]);

  return (
    <>
      <CardContent>
        <Stack mt={2} spacing={3}>
          {/* <Typography variant="h6">ADD HAZARD POINT</Typography> */}
          <Typography variant="h6">สร้างพื้นที่จุดเสี่ยงจุดอันตราย</Typography>
          <Stack spacing={2}>
            <Dropdown
              variant="outlined"
              label="Category"
              options={categoryData}
              value={categorySelected}
              onChange={(value) => {
                setCategorySelected(value);
              }}
            />
            <Dropdown
              variant="outlined"
              label="Alarm"
              options={alarmDataSource}
              value={alarmSelected}
              onChange={(value) => {
                setAlarmSelected(value);
              }}
            />
            <Stack
              alignItems={"center"}
              justifyContent={"space-between"}
              direction={"row"}
              spacing={2}
            >
              <Stack direction={"row"} spacing={1}>
                <IconButton onClick={audioPlay}>
                  <GraphicEq />
                </IconButton>
                <Typography noWrap component={"div"} alignSelf={"center"}>
                  เล่นเสียง
                </Typography>
              </Stack>
              {showPercent && (
                <Box flexGrow={1} pt={0.4}>
                  <LinearProgress
                    variant="determinate"
                    value={percent}
                    color="inherit"
                  />
                </Box>
              )}
              <audio
                ref={audioRef}
                onLoadedData={handlePlaySound}
                onTimeUpdate={timeupdate}
                onEnded={handleEndedSound}
              >
                <source src={audioSrc} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
              <ButtonGroup color="primary" size="small">
                <Tooltip title="หยุดการวาด" placement="top" arrow>
                  <Button
                    variant={!drawingMode ? "contained" : "outlined"}
                    onClick={() => setDrawingMode(null)}
                  >
                    <BackHandTwoTone />
                  </Button>
                </Tooltip>
                <Tooltip title="วาดวงกลม" placement="top" arrow>
                  <Button
                    variant={drawingMode === 1 ? "contained" : "outlined"}
                    onClick={() => setDrawingMode(1)}
                  >
                    <CircleTwoTone />
                  </Button>
                </Tooltip>
                <Tooltip title="วาดรูปร่าง" placement="top" arrow>
                  <Button
                    variant={drawingMode === 2 ? "contained" : "outlined"}
                    onClick={() => setDrawingMode(2)}
                  >
                    <LabelImportantTwoTone />
                  </Button>
                </Tooltip>
                <Tooltip title="วาดเส้น" placement="top" arrow>
                  <Button
                    variant={drawingMode === 3 ? "contained" : "outlined"}
                    onClick={() => setDrawingMode(3)}
                  >
                    <Timeline />
                  </Button>
                </Tooltip>
                <Tooltip title="วาดสี่เหลี่ยมผืนผ้า" placement="top" arrow>
                  <Button
                    variant={drawingMode === 4 ? "contained" : "outlined"}
                    onClick={() => setDrawingMode(4)}
                  >
                    <RectangleTwoTone />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Stack>

            <TextField
              onChange={(el) => {
                locationNameError && setLocationNameError("");
                setLocationName(el.target.value);
              }}
              value={locationName}
              label="Location Name"
              autoComplete="off"
              error={locationNameError ? true : false}
              helperText={locationNameError}
              slotProps={{ inputLabel: { shrink: true } }}
            />
            <TextField
              slotProps={{ inputLabel: { shrink: true } }}
              label="Speed Limit"
              value={speedLimit}
              autoComplete="off"
              disabled={categorySelected !== 1}
              error={speedLimitError ? true : false}
              helperText={speedLimitError}
              onChange={(el) => {
                speedLimitError && setSpeedLimitError("");
                setSpeedLimit(el.target.value);
              }}
            />
            <TextField
              label="Radius"
              slotProps={{ inputLabel: { shrink: true } }}
              value={radius}
              autoComplete="off"
              disabled={drawingMode === 3 ? false : true}
              error={radiusError ? true : false}
              helperText={radiusError}
              onChange={(el) => {
                radiusError && setRadiusError("");
                setRadius(el.target.value);
              }}
            />

            <Box>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <input
                  ref={refFile}
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Button
                  onClick={() => refFile?.current.click()}
                  color="info"
                  startIcon={<CloudUpload />}
                >
                  อัพโหลดรูปภาพ
                </Button>
                <Button
                  onClick={handleClearImage}
                  color="info"
                  startIcon={<Delete />}
                >
                  ลบรูปภาพ
                </Button>
              </Stack>
              <Card
                elevation={0}
                sx={{
                  height: 240,
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="outlined"
              >
                {!imagePath && !uploading && (
                  <CardContent>
                    <Stack alignItems={"center"} spacing={1}>
                      <HideImage sx={{ fontSize: "64px", color: grey[400] }} />
                      <Typography color={grey[400]} variant="caption">
                        ไม่มีรูปภาพ
                      </Typography>
                    </Stack>
                  </CardContent>
                )}
                {imagePath && !uploading && (
                  <Image src={`${api.imageUrl()}${imagePath}`} />
                )}
                {uploading && (
                  <CardContent>
                    <Spin />
                  </CardContent>
                )}
              </Card>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions sx={{ ml: 1 }}>
        <Button
          startIcon={<Cancel />}
          onClick={onCancleClick}
          variant="outlined"
          color="info"
        >
          ยกเลิก
        </Button>
        <LoadingButton
          startIcon={<Save />}
          onClick={onSave}
          variant="contained"
          //color="success"
          loading={loading}
        >
          บันทึก
        </LoadingButton>
      </CardActions>
    </>
  );
}

export default PointAddNew;
