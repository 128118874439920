import Swal from "sweetalert2";

/**
 * notiError
 * @param {Object} props
 * @param {string}  props.message
 * @param {"bottom-left"|"center"|"bottom-right"} props.position
 */
export const notiError = ({ message, position }) => {
  Swal.fire({
    toast: true,
    title: message,
    icon: "error",
    timer: 3000,
    position: position ?? "bottom-left",
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

/**
 * notiSuccess
 * @param {Object} props
 * @param {string}  props.message
 * @param {"bottom-left"|"center"|"bottom-right"} props.position
 */
export const notiSuccess = ({ message, position }) => {
  Swal.fire({
    toast: true,
    title: message,
    icon: "success",
    timer: 3000,
    position: position ?? "bottom-left",
    timerProgressBar: true,
    showConfirmButton: false,
  });
};
