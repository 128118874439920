export const categoryData = [
  { value: 0, label: "เขตชุมชน" },
  { value: 1, label: "จำกัดความเร็ว" },
  { value: 2, label: "จอดรถพื้นที่เสี่ยง / จอดไหล่ทาง" },
];

export const getCategoryName = (value) => {
  if (value === 2) {
    return "พื้นที่เสี่ยง";
  }
  return categoryData.find((a) => a.value === value)?.label ?? "";
};

export const alarmData = [
  {
    value: 0,
    label: "พื้นที่ชุมชนจำกัดความเร็ว 30 กม/ชม.",
    soundPath: "/sounds/1.mp3",
    category: [0],
  },
  {
    value: 1,
    label: "พื้นที่ชุมชนจำกัดความเร็ว 40 กม/ชม.",
    soundPath: "/sounds/2.mp3",
    category: [0],
  },
  {
    value: 2,
    label: "พื้นที่ชุมชนจำกัดความเร็ว 45 กม/ชม.",
    soundPath: "/sounds/3.mp3",
    category: [0],
  },
  {
    value: 3,
    label: "พื้นที่ชุมชนจำกัดความเร็ว 50 กม/ชม.",
    soundPath: "/sounds/4.mp3",
    category: [0],
  },
  {
    value: 4,
    label: "พื้นที่ชุมชนจำกัดความเร็ว 60 กม/ชม.",
    soundPath: "/sounds/5.mp3",
    category: [0],
  },
  {
    value: 5,
    label: "ความเร็วเกินกำหนด",
    soundPath: "/sounds/20.mp3",
    category: [1],
  },
  {
    value: 6,
    label: "ความเร็วเกินกำหนด 30 กม/ชม.",
    soundPath: "/sounds/9.mp3",
    category: [1],
  },
  {
    value: 7,
    label: "ความเร็วเกินกำหนด 40 กม/ชม.",
    soundPath: "/sounds/10.mp3",
    category: [1],
  },
  {
    value: 8,
    label: "ความเร็วเกินกำหนด 45 กม/ชม.",
    soundPath: "/sounds/11.mp3",
    category: [1],
  },
  {
    value: 9,
    label: "ความเร็วเกินกำหนด 50 กม/ชม.",
    soundPath: "/sounds/6.mp3",
    category: [1],
  },
  {
    value: 10,
    label: "ความเร็วเกินกำหนด 60 กม/ชม.",
    soundPath: "/sounds/7.mp3",
    category: [1],
  },
  {
    value: 11,
    label: "ความเร็วเกินกำหนด 75 กม/ชม.",
    soundPath: "/sounds/8.mp3",
    category: [1],
  },
  {
    value: 12,
    label: "ความเร็วเกินกำหนด 80 กม/ชม.",
    soundPath: "/sounds/12.mp3",
    category: [1],
  },
  {
    value: 13,
    label: "ความเร็วเกินกำหนด 90 กม/ชม.",
    soundPath: "/sounds/13.mp3",
    category: [1],
  },
  {
    value: 14,
    label: "จอดรถพื้นที่เสี่ยง / จอดไหล่ทาง",
    soundPath: "/sounds/21.mp3",
    category: [2],
  },
  {
    value: 15,
    label: "ทางร่วม / ทางแยก",
    soundPath: "/sounds/22.mp3",
    category: [2],
  },
  {
    value: 16,
    label: "สี่แยกไฟแดง",
    soundPath: "/sounds/23.mp3",
    category: [2],
  },
  {
    value: 17,
    label: "เตือนกิ่งไม้",
    soundPath: "/sounds/30.mp3",
    category: [2],
  },
];
