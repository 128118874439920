import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  useTheme,
  Button,
} from "@mui/material";
import {
  DarkMode,
  LightMode,
  Menu,
  MenuOpen,
  PowerSettingsNew,
} from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "./App";
import { MainLayOutContect } from "./MainLayout";
import Swal from "sweetalert2";

export default function NavBar() {
  const theme = useTheme();
  const { handleMode } = useContext(AppContext);
  const { togleCollapse, setTogleCollapse } = useContext(MainLayOutContect);
  return (
    <AppBar position="sticky" enableColorOnDark>
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setTogleCollapse(!togleCollapse)}
        >
          {togleCollapse ? <MenuOpen /> : <Menu />}
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          GPS ThaiTracking : Logistics Control Center Management (LCCM)
        </Typography>
        <IconButton onClick={handleMode}>
          {theme.palette.mode === "dark" ? (
            <LightMode />
          ) : (
            <DarkMode style={{ color: "#FFFFFF" }} />
          )}
        </IconButton>
        <Button
          sx={{ ml: 2 }}
          variant="text"
          endIcon={<PowerSettingsNew />}
          color="inherit"
          onClick={() => {
            Swal.fire({
              title: "ต้องการออกจากระบบ?",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#1976D2",
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("token");
                window.location.href = "/authen";
              }
            });
          }}
        >
          Log out
        </Button>
      </Toolbar>
    </AppBar>
  );
}
