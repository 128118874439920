import {
  Box,
  Card,
  CardContent,
  Checkbox,
  createTheme,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import bgImage from "../images/bg.jpg";
import { KeyOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import { ApiService } from "../utils/ApiService";
import { LoadingButton } from "@mui/lab";
const api = new ApiService(
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEBUG
    : process.env.REACT_APP_API_URL_PRODUCT
);
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
    background: { paper: "#223862" },
  },
  typography: {
    fontFamily: "kanit",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderColor: "#283f6c",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#283f6c",
        },
      },
    },
  },
});
function App() {
  const [visibility, setVisibility] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleVisibility = () => {
    setVisibility(!visibility);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    //!username && setUsernameError("Enter username.");
    //!password && setPasswordError("Enter Password.");
    if (!username || !password) {
      LoginAlert("Error: Invalid password");
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const loginResult = await api.post("/login", { username, password });
      localStorage.setItem("token", loginResult.token);
      if (rememberMe) {
        localStorage.setItem("rememberMe", true);
        localStorage.setItem("username", username);
      } else {
        localStorage.removeItem("rememberMe");
        localStorage.removeItem("username");
      }
      setLoading(false);
      window.location.href = "/";
    } catch (error) {
      LoginAlert(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    const usernameSession = localStorage.getItem("username");
    if (token) {
      window.location.href = "/";
    } else {
      if (usernameSession) {
        setUsername(usernameSession);
      }
    }
  }, []);
  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundBlendMode: "overlay",
          backgroundColor: "#004faa80",
        }}
      >
        <Card
          elevation={0}
          variant="outlined"
          sx={{ width: 450, bgcolor: "#0f2055" }}
        >
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h5" fontWeight={500}>
                Log in
              </Typography>
              <form onSubmit={handleSubmit}>
                <Stack spacing={1}>
                  <TextField
                    autoComplete="off"
                    label="Username"
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant="filled"
                    color="info"
                    error={usernameError ? true : false}
                    helperText={usernameError}
                    value={username}
                    onChange={(el) => {
                      usernameError && setUsernameError("");
                      setUsername(el.target.value);
                    }}
                  />
                  <TextField
                    label="Password"
                    slotProps={{
                      inputLabel: { shrink: true },
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleVisibility}>
                              {visibility ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                    variant="filled"
                    color="info"
                    type={visibility ? "text" : "password"}
                    autoComplete="current-password"
                    error={passwordError ? true : false}
                    helperText={passwordError}
                    value={password}
                    onChange={(el) => {
                      passwordError && setPasswordError("");
                      setPassword(el.target.value);
                    }}
                  />
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="rememberMe"
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                      }
                      label="Remeber me"
                    />
                  </Box>
                  <LoadingButton
                    type="submit"
                    startIcon={<KeyOutlined />}
                    variant="contained"
                    loading={loading}
                  >
                    Log in
                  </LoadingButton>
                </Stack>
              </form>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </ThemeProvider>
  );
}
export default App;

function LoginAlert(message) {
  Swal.fire({
    toast: true,
    timer: 1500,
    timerProgressBar: true,
    title: message,
    position: "center",
    showConfirmButton: false,
    icon: "error",
  });
}
