import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
/**
 * Dropdown
 * @param {Object} props
 * @param {'filled'|'outlined'|'standard'} props.variant
 * @param {Array<{label: string, value: any}>} props.options
 * @param {String} props.label
 * @param {Number} props.value
 * @param {Function} props.onChange
 */
function Dropdown({ variant, options, label, value, onChange }) {
  return (
    <FormControl variant={variant} fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        onChange={(e) => {
          typeof onChange === "function" && onChange(e.target.value);
        }}
        label={label ? label : ""}
        value={value}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
