import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ConfigProvider,
  Divider,
  Input,
  Modal,
  Select,
  Space,
  Table,
  theme,
  Typography,
} from "antd";
import Draggable from "react-draggable";
import { Stack, useTheme } from "@mui/material";
import { AlertFilled, SearchOutlined } from "@ant-design/icons";
import { ApiService } from "../utils/ApiService";
import { notiError, notiSuccess } from "../utils/notiUltils";

const api = new ApiService(
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEBUG
    : process.env.REACT_APP_API_URL_PRODUCT
);

const VehicleAuthorization = ({ open, setOpen, selectedPoint }) => {
  const muiTheme = useTheme();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedAuthorization, setSelectedAuthorization] = useState(1);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState("");

  const draggleRef = useRef(null);
  const handleCancel = (e) => {
    setOpen(false);
  };
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleAuthorization = (row) => {
    const postData = {
      geoFenceId: selectedPoint?.GeoFenceID,
      vehicleId: row.VehicleID,
    };
    if (selectedAuthorization === 1) {
      api
        .post("/role", postData, localStorage.getItem("token"))
        .then((result) => {
          dataSource.splice(
            dataSource.findIndex((a) => a.VehicleID === postData.vehicleId),
            1
          );
          setDataSource([...dataSource]);
          notiSuccess({ message: result.message, position: "center" });
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
        });
    } else if (selectedAuthorization === 0) {
      api
        .delete(
          `/role/${postData.geoFenceId}/${postData.vehicleId}`,
          null,
          localStorage.getItem("token")
        )
        .then((result) => {
          dataSource.splice(
            dataSource.findIndex((a) => a.VehicleID === postData.vehicleId),
            1
          );
          setDataSource([...dataSource]);
          notiSuccess({ message: result.message, position: "center" });
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
        });
    }
  };

  const handleAuthorizedAll = () => {
    const dataSelected = dataSource.map((a) => a.VehicleID);
    const postData = {
      geoFenceId: selectedPoint?.GeoFenceID,
      vehicleId: dataSelected,
    };
    if (selectedAuthorization === 1) {
      api
        .post("/role", postData, localStorage.getItem("token"))
        .then((result) => {
          setDataSource([]);
          notiSuccess({ message: result.message, position: "center" });
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
        });
    } else if (selectedAuthorization === 0) {
      api
        .delete(
          `/role/${postData.geoFenceId}`,
          postData,
          localStorage.getItem("token")
        )
        .then((result) => {
          setDataSource([]);
          notiSuccess({ message: result.message, position: "center" });
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
        });
    }
  };

  const handleAuthorizedItems = () => {
    const dataSelected = selectedRowKeys;
    const postData = {
      geoFenceId: selectedPoint?.GeoFenceID,
      vehicleId: dataSelected,
    };
    if (selectedAuthorization === 1) {
      api
        .post("/role", postData, localStorage.getItem("token"))
        .then((result) => {
          const tmp = dataSource.filter((a) => !dataSelected.includes(a.key));
          setDataSource(tmp);
          setSelectedRowKeys([]);
          notiSuccess({ message: result.message, position: "center" });
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
        });
    } else if (selectedAuthorization === 0) {
      api
        .delete(
          `/role/${postData.geoFenceId}`,
          postData,
          localStorage.getItem("token")
        )
        .then((result) => {
          const tmp = dataSource.filter((a) => !dataSelected.includes(a.key));
          setDataSource(tmp);
          setSelectedRowKeys([]);
          notiSuccess({ message: result.message, position: "center" });
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
        });
    }
  };

  useEffect(() => {
    setDataSource([]);
    setSelectedRowKeys([]);
    setLoading(true);
    if (selectedAuthorization === 1) {
      api
        .post(
          "/vehicleUnauthorized",
          { geoFenceId: selectedPoint?.GeoFenceID },
          localStorage.getItem("token")
        )
        .then((result) => {
          for (let i in result) {
            result[i].key = result[i].VehicleID;
            result[i].index = parseInt(i) + 1;
          }
          setDataSource(result);
          setLoading(false);
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
          setLoading(false);
        });
    } else if (selectedAuthorization === 0) {
      api
        .post(
          "/vehicleAuthorized",
          { geoFenceId: selectedPoint?.GeoFenceID },
          localStorage.getItem("token")
        )
        .then((result) => {
          for (let i in result) {
            result[i].key = result[i].VehicleID;
            result[i].index = parseInt(i) + 1;
          }
          setDataSource(result);
          setLoading(false);
        })
        .catch((error) => {
          notiError({ message: error, position: "center" });
          setLoading(false);
        });
    } else {
      setDataSource([]);
      setLoading(false);
    }
  }, [selectedAuthorization, selectedPoint]);

  useEffect(() => {
    const elements = document.getElementsByClassName("ant-table-body");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.overflow = "auto";
    }
  }, [dataSource, searchText]);

  const hasSelected = selectedRowKeys.length > 0;

  const filteredData = dataSource.filter((item) => {
    return Object.values(item).some((val) =>
      val.toString().toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <ConfigProvider
      theme={{
        token:
          muiTheme.palette.mode === "dark"
            ? {
                //fontFamily: "kanit",
                //colorBgContainer: "#223862",
                colorBgBase: "#0e1626",
              }
            : {
                //fontFamily: "kanit",
                //colorBgContainer: "#f6f5f5",
              },
        algorithm:
          muiTheme.palette.mode === "dark"
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
      }}
    >
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {}}
            onBlur={() => {}}
            // end
          >
            Vehicle Authorization
          </div>
        }
        open={open}
        destroyOnClose={true}
        centered
        maskClosable={false}
        onCancel={handleCancel}
        style={{ minWidth: 520 }}
        footer={[
          <Button
            type="primary"
            style={{ width: 210 }}
            disabled={!hasSelected}
            key="authorized"
            onClick={handleAuthorizedItems}
          >
            {selectedAuthorization === 1 ? "Authorized" : "Unathorized"}{" "}
            {hasSelected ? `${selectedRowKeys.length} items` : null}
          </Button>,
          <Button
            onClick={handleAuthorizedAll}
            style={{ width: 120 }}
            type="primary"
            key="authorizedAll"
            disabled={dataSource.length === 0}
          >
            {selectedAuthorization === 1
              ? "Authorized All"
              : "Unauthorized All"}
          </Button>,
          <Button style={{ width: 120 }} onClick={handleCancel} key="close">
            Close
          </Button>,
        ]}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Stack direction="column" spacing={2} mt={2}>
          <Stack direction={"row"} spacing={1}>
            <Select
              options={[
                { value: 0, label: "Authorized" },
                { value: 1, label: "Unauthorized" },
              ]}
              style={{ minWidth: 130 }}
              value={selectedAuthorization}
              onChange={(value) => setSelectedAuthorization(value)}
            />
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search..."
              style={{ width: "100%" }}
              allowClear
              onChange={handleSearch}
            />
          </Stack>
          <Space>
            <Typography.Text>Selected Point: </Typography.Text>
            <Typography.Text type="secondary">
              {selectedPoint?.LocationName}
            </Typography.Text>
          </Space>
          <Table
            columns={[
              {
                title: "Index",
                dataIndex: "index",
                width: 70,
                align: "center",
              },
              { title: "Plate NO.", dataIndex: "VehicleName" },
              {
                title: "Operate",
                align: "center",
                width: 80,
                render: (a) => (
                  <Button
                    size="small"
                    shape="circle"
                    type={selectedAuthorization === 1 ? "primary" : "default"}
                    icon={<AlertFilled />}
                    //loading={a.loading ? true : false}
                    onClick={() => {
                      handleAuthorization(a);
                    }}
                  />
                ),
              },
            ]}
            bordered
            style={{ height: "calc(100vh - 450px)" }}
            //sticky={true}
            size="small"
            rowSelection={rowSelection}
            dataSource={filteredData}
            //pagination={false}
            scroll={{ y: `calc(100vh - 535px)` }}
            loading={loading}
          />
        </Stack>
        <Divider />
      </Modal>
    </ConfigProvider>
  );
};
export default VehicleAuthorization;
