import { Card, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { ConfigOperationsContext } from ".";
import PointPreview from "./PointPreview";
import PointAddNew from "./PointAddNew";
import PointEdit from "./PointEdit";

function ConfigOperations() {
  const { display, operation } = useContext(ConfigOperationsContext);
  const theme = useTheme();
  return (
    <Card
      elevation={0}
      variant="outlined"
      sx={{
        minWidth: 450,
        maxWidth: 450,
        borderLeft: 0,
        borderTop: 0,
        borderBottom: 0,
        borderRadius: 0,
        overflow: "auto",
        position: "relative",
        display,        
        backgroundColor: theme.palette.mode === "dark" ? null : "#FFFFFF50",
      }}
    >
      {operation === 1 && <PointPreview />}
      {operation === 2 && <PointAddNew />}
      {operation === 3 && <PointEdit />}
    </Card>
  );
}

export default ConfigOperations;
